@use '@cloudscape-design/design-tokens/index' as awsui;

.ta-text-filter {
    flex-grow: 6;
    width: auto;
    max-width: 50%;
    margin-right: 1rem;
}

.ta-select-filter {
    max-width: 130px;
    flex-grow: 2;
    width: auto;
    margin-right: 1rem;
}

.ta-filter-container {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 10;
    margin-right: 2rem;
    margin-bottom: 1rem;
}

.ta-filtering-results {
    margin-right: 1rem;
    line-height: 2rem;
    color: awsui.$color-text-form-default;
    margin-bottom: 1rem;
}
