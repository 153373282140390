@use '@cloudscape-design/design-tokens/index' as awsui;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
  'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

// Page header
#tw-header {
  position: sticky;
  display: flex;
  background-color: #0f1b2a;
  padding: 10px 60px;
  color: white;
  z-index: 9999;
  height: 32px;
  top: 0;
  align-items: center;

  & a {
    display: flex;
  }

  & > div:nth-child(2) {
    text-align: right;
  }

  .sign-out-button {
    color: white !important;
    font-weight: 500 !important;

    &:hover {
      border-color: transparent !important;
      background: transparent !important;
      color: #ec7211 !important;
    }
  }
}

.tw-link {
  text-decoration: none !important;
  font-size: inherit !important;
}

hr {
  color: #eaeded;
}

.tw-zero-padded-cards {
  li > div {
    padding: 0 !important;
  }
}

// defining commonly used styles.
.tw-flex {
  display: flex;
}

.tw-item-center {
  align-items: center;
}

.tw-justify-center {
  justify-content: center;
}

.tw-ma0 {
  margin: 0px !important;
}

.tw-pa0 {
  padding: 0px !important;
}

.tw-pa2 {
  padding: awsui.$space-scaled-s !important;
}

.tw-pl4 {
  padding-left: awsui.$space-scaled-l;
}

.tw-mt1 {
  margin-top: awsui.$space-scaled-xs;
}

.tw-mt2 {
  margin-top: awsui.$space-scaled-s;
}

.tw-mt3 {
  margin-top: awsui.$space-scaled-m;
}

.tw-mt4 {
  margin-top: awsui.$space-scaled-l;
}

.tw-mt5 {
  margin-top: awsui.$space-scaled-xl;
}

.tw-mb1 {
  margin-bottom: awsui.$space-scaled-xs;
}

.tw-mb2 {
  margin-bottom: awsui.$space-scaled-s;
}

.tw-mb3 {
  margin-bottom: awsui.$space-scaled-m;
}

.tw-mb4 {
  margin-bottom: awsui.$space-scaled-l;
}

.tw-mb5 {
  margin-bottom: awsui.$space-scaled-xl;
}

.tw-ml1 {
  margin-left: awsui.$space-scaled-xs;
}

.tw-ml2 {
  margin-left: awsui.$space-scaled-s;
}

.tw-ml3 {
  margin-left: awsui.$space-scaled-m;
}

.tw-ml4 {
  margin-left: awsui.$space-scaled-l;
}

.tw-ml5 {
  margin-left: awsui.$space-scaled-xl;
}

.tw-ml-auto {
  margin-left: auto !important;
}

.tw-mr1 {
  margin-right: awsui.$space-scaled-xs;
}

.tw-mr2 {
  margin-right: awsui.$space-scaled-s;
}

.tw-white {
  color: white !important;
}

.tw-light-text {
  color: #545658 !important;
}

.tw-fw-500 {
  font-weight: 500 !important;
}

@for $i from 1 through 10 {
  .tw-min-vw-#{$i * 10} {
    min-width: $i * 10vw;
  }
}

.tw-page-loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

/* inherit font for all child elements */
.tw-font-inherit * {
  font: inherit !important;
}

.tw-flex-grow {
  flex-grow: 1;
}

.tw-word-wrap {
  white-space: pre-wrap;
}

.tw-ui-preview-scroll {
  max-height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
