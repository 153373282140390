.tw-sign-in-div {
  display:flex;
  justify-content: center;
  padding-top: 30px;
}

.tw-sign-in-container {
  width: 50%;
}

.tw-sign-in-input-fields {
  input {
    width: 75% !important;
  }
}