.tw-auth-profile-dropdown {
  button {
    background-color: #0f1b2a !important;
    border: none !important;
    font-weight: 500 !important;
    color: white !important;

    &:hover {
      background: #0f1b2a !important;
      color: #ec7211 !important;
    }
  }

  span {
    background-color: #0f1b2a !important;
    color: inherit;
  }

  li {
    border-top: 1px solid #eaeded !important;
    border-bottom: 1px solid #eaeded !important;
    color: #545B64 !important;

    span {
      background-color: white !important;
      color: inherit;
    }

    &:hover {
      color: #0073BB !important;;
    }
  }
}
