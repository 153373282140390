@use '../../../../node_modules/@cloudscape-design/design-tokens/index' as awsui;

.tw-home-header {
  background-color: awsui.$color-background-home-header;
}

.tw-home-header-title {
  color: awsui.$color-text-home-header-default;
}

.tw-home-header-sub-title {
  color: awsui.$color-text-home-header-secondary;
}

.tw-home-header-title > * {
  max-width: 700px;
}

.tw-home-cards {
  li > div {
    background-color: awsui.$color-background-home-header !important;
  }
}

.tw-home-category-logo {
  width: 56px;
  height: 56px;
}

.tw-home-link {
  color: white;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
}
